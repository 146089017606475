//
//
//
//

import OrdersPage from "@/common/components/Orders/OrderList";
export default {
  name: "GADashboard",
  components: {
    OrdersPage
  },
  data: () => ({
    dataFilter: {
      trackingStatus: ["Open", "In Progress"],
      orderStatus: ["Paid"]
    }
  })
};