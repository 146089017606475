import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GAGetOrders } from "@/common/lib/api";
import { queryGeneticAnalystByAccountId, queryGeneticAnalysisByGeneticAnalysisTrackingId } from "@debionetwork/polkadot-provider";
import { generalDebounce } from "@/common/lib/utils";
import { geneticAnalystIllustration, eyeIcon, alertIcon, searchIcon } from "@debionetwork/ui-icons";
import { mapState } from "vuex";
import localStorage from "@/common/lib/local-storage";
import { fromEther, formatUSDTE } from "@/common/lib/balance-format";
export default {
  name: "OrderList",
  data: () => ({
    geneticAnalystIllustration,
    eyeIcon,
    alertIcon,
    searchIcon,
    searchQuery: "",
    verificationStatus: null,
    cardBlock: false,
    isLoading: false,
    orderLists: [],
    headers: [{
      text: "Order ID",
      value: "id",
      sortable: true
    }, {
      text: "Service Name",
      value: "service_info.name",
      sortable: true
    }, {
      text: "Order Date",
      value: "created_at",
      sortable: true
    }, {
      text: "Price",
      value: "price",
      sortable: true
    }, {
      text: "Status",
      value: "status",
      sortable: true
    }, {
      text: "Action",
      value: "action",
      align: "center",
      sortable: false
    }]
  }),
  props: {
    filter: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      lastEventData: state => state.substrate.lastEventData,
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3
    })
  },
  watch: {
    lastEventData: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function (val) {
        if ((val === null || val === void 0 ? void 0 : val.section) === "geneticAnalysisOrders") await this.getOrdersData();
        if ((val === null || val === void 0 ? void 0 : val.method) === "GeneticAnalystUpdateVerificationStatus") await this.getVerificationStatus();
      }, 100)
    },
    searchQuery: generalDebounce(async function (newVal) {
      await this.getOrdersData(newVal);
    }, 500)
  },
  async mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 959) this.cardBlock = true;else this.cardBlock = false;
    });
    await this.getVerificationStatus();
    await this.getOrdersData();
  },
  methods: {
    async getVerificationStatus() {
      const {
        verificationStatus
      } = await queryGeneticAnalystByAccountId(this.api, localStorage.getAddress());
      this.verificationStatus = verificationStatus;
    },
    async getGAByTrackingId(trackingId) {
      const analysisData = await queryGeneticAnalysisByGeneticAnalysisTrackingId(this.api, trackingId);
      return analysisData;
    },
    async getOrdersData(keyword) {
      this.isLoading = true;
      let orderLists = [];
      let orders = [];
      try {
        const orderData = await GAGetOrders(keyword);
        for (const order of orderData.data) {
          var _sourceData$service_i, _sourceData$service_i2;
          const sourceData = order._source;
          const price = await fromEther((_sourceData$service_i = sourceData.service_info) === null || _sourceData$service_i === void 0 ? void 0 : (_sourceData$service_i2 = _sourceData$service_i.prices_by_currency[0]) === null || _sourceData$service_i2 === void 0 ? void 0 : _sourceData$service_i2.total_price, sourceData === null || sourceData === void 0 ? void 0 : sourceData.currency);
          const formatedPrice = "".concat(price, " ").concat(formatUSDTE(sourceData === null || sourceData === void 0 ? void 0 : sourceData.currency));
          const data = {
            ...sourceData,
            price: formatedPrice,
            created_at: new Date(+sourceData.created_at.replaceAll(",", "")).toLocaleString("en-GB", {
              day: "numeric",
              year: "numeric",
              month: "short"
            })
          };
          if (this.filter.orderStatus.includes(sourceData.status)) orders.push(data);
        }
      } catch (e) {
        console.error(e);
      }
      for (const item of orders) {
        let _item = item;
        let status = item.status;
        const analysisData = await this.getGAByTrackingId(_item.genetic_analysis_tracking_id).catch(() => null);
        const GENETIC_STATUS = {
          REGISTERED: "Open",
          INPROGRESS: "In Progress",
          REJECTED: "Rejected",
          RESULTREADY: "Done"
        };
        if (analysisData) {
          status = GENETIC_STATUS[analysisData.status.toUpperCase()];
          if (this.filter.trackingStatus.includes(status)) orderLists.push({
            ..._item,
            status
          });
        }
      }
      this.orderLists = orderLists;
      this.isLoading = false;
    }
  }
};